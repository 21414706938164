var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { action, observable } from 'mobx';
import useStore from '@/common/hooks/useStore';
class GlobalSearchConfigStore {
    rowEndComponent = null;
    setRowEndComponent = (component) => {
        this.rowEndComponent = component;
    };
    resetRowEndComponent = () => {
        this.rowEndComponent = null;
    };
}
__decorate([
    observable
], GlobalSearchConfigStore.prototype, "rowEndComponent", void 0);
__decorate([
    action
], GlobalSearchConfigStore.prototype, "setRowEndComponent", void 0);
__decorate([
    action
], GlobalSearchConfigStore.prototype, "resetRowEndComponent", void 0);
export const useGlobalSearchConfigStore = () => useStore(GlobalSearchConfigStore);
export const globalSearchConfigStore = new GlobalSearchConfigStore();
export default GlobalSearchConfigStore;
