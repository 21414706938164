// extracted by mini-css-extract-plugin
var _1 = "base-link";
var _2 = "#76F366";
var _3 = "#EB8632";
var _4 = "#ECC834";
var _5 = "#21FC87";
var _6 = "#EA4A30";
var _7 = "#C7EA45";
var _8 = "#272B2B";
var _9 = "#BDBDBD";
var _a = "#7D7D7D";
var _b = "#6A0DAD";
var _c = "#3A65FF";
var _d = "#4A90E2";
var _e = "#ffffff";
var _f = "metricScaleContainerActive";
var _10 = "metricScaleContainerInactive";
var _11 = "metricScaleContainerLabel";
var _12 = "metricScaleContainerLarge";
var _13 = "metricScaleContainerLargeABS";
var _14 = "metricScaleContainerLargeIcon";
var _15 = "metricScaleContainerLargeScale";
var _16 = "metricScaleContainerLargeTitle";
var _17 = "metricScaleContainerMedium";
var _18 = "metricScaleContainerMediumIcon";
var _19 = "metricScaleContainerMediumScale";
var _1a = "metricScaleContainerMediumTitle";
var _1b = "metricScaleContainerMediumWrapper";
var _1c = "metricScaleContainerSmall";
var _1d = "metricScaleContainerSmallIcon";
var _1e = "metricScaleContainerSmallTitle";
var _1f = "metricScaleContainerXSmall";
var _20 = "metricScaleContainerXSmallABS";
var _21 = "metricScaleContainerXSmallScale";
var _22 = "product-block-grid";
var _23 = "product-block-subgrid";
var _24 = "reset-button";
var _25 = "1200px";
var _26 = "992px";
var _27 = "1366px";
var _28 = "768px";
var _29 = "1475px";
var _2a = "576px";
var _2b = "1600px";
var _2c = "320px";
var _2d = "2500px";
var _2e = "single-page-responsive-font-size";
export { _1 as "base-link", _2 as "colorGradeEX", _3 as "colorGradeFR", _4 as "colorGradeGD", _5 as "colorGradeOU", _6 as "colorGradePR", _7 as "colorGradeVG", _8 as "colorNeutral", _9 as "colorNeutralLighest", _a as "colorNeutralLight", _b as "colorNonOwnerSweetLine", _c as "colorOwnerSweetLine", _d as "colorRegularLinks", _e as "colorWhite", _f as "metricScaleContainerActive", _10 as "metricScaleContainerInactive", _11 as "metricScaleContainerLabel", _12 as "metricScaleContainerLarge", _13 as "metricScaleContainerLargeABS", _14 as "metricScaleContainerLargeIcon", _15 as "metricScaleContainerLargeScale", _16 as "metricScaleContainerLargeTitle", _17 as "metricScaleContainerMedium", _18 as "metricScaleContainerMediumIcon", _19 as "metricScaleContainerMediumScale", _1a as "metricScaleContainerMediumTitle", _1b as "metricScaleContainerMediumWrapper", _1c as "metricScaleContainerSmall", _1d as "metricScaleContainerSmallIcon", _1e as "metricScaleContainerSmallTitle", _1f as "metricScaleContainerXSmall", _20 as "metricScaleContainerXSmallABS", _21 as "metricScaleContainerXSmallScale", _22 as "product-block-grid", _23 as "product-block-subgrid", _24 as "reset-button", _25 as "screenlg", _26 as "screenmd", _27 as "screenml", _28 as "screensm", _29 as "screenxlg", _2a as "screenxs", _2b as "screenxxlg", _2c as "screenxxs", _2d as "screenxxxlg", _2e as "single-page-responsive-font-size" }
export default { "base-link": _1, "colorGradeEX": _2, "colorGradeFR": _3, "colorGradeGD": _4, "colorGradeOU": _5, "colorGradePR": _6, "colorGradeVG": _7, "colorNeutral": _8, "colorNeutralLighest": _9, "colorNeutralLight": _a, "colorNonOwnerSweetLine": _b, "colorOwnerSweetLine": _c, "colorRegularLinks": _d, "colorWhite": _e, "metricScaleContainerActive": _f, "metricScaleContainerInactive": _10, "metricScaleContainerLabel": _11, "metricScaleContainerLarge": _12, "metricScaleContainerLargeABS": _13, "metricScaleContainerLargeIcon": _14, "metricScaleContainerLargeScale": _15, "metricScaleContainerLargeTitle": _16, "metricScaleContainerMedium": _17, "metricScaleContainerMediumIcon": _18, "metricScaleContainerMediumScale": _19, "metricScaleContainerMediumTitle": _1a, "metricScaleContainerMediumWrapper": _1b, "metricScaleContainerSmall": _1c, "metricScaleContainerSmallIcon": _1d, "metricScaleContainerSmallTitle": _1e, "metricScaleContainerXSmall": _1f, "metricScaleContainerXSmallABS": _20, "metricScaleContainerXSmallScale": _21, "product-block-grid": _22, "product-block-subgrid": _23, "reset-button": _24, "screenlg": _25, "screenmd": _26, "screenml": _27, "screensm": _28, "screenxlg": _29, "screenxs": _2a, "screenxxlg": _2b, "screenxxs": _2c, "screenxxxlg": _2d, "single-page-responsive-font-size": _2e }
