// This module is used for access to ENV variables during runtime
export default {
    MOBX_LOG_ENABLED: MOBX_LOG_ENABLED,
    CLIENT_ID: CLIENT_ID,
    NETBOX_CLIENT_ID: NETBOX_CLIENT_ID,
    IS_DEV: IS_DEV,
    PLAYER_JS_URL: '',
    PLAYER_CSS_URL: '',
    APP_VERSION: APP_VERSION,
};
