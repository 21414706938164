import {action, computed, observable, toJS} from 'mobx';
import { TYPE_DIAMOND_COLORLESS, TYPE_DIAMOND_FANCY, TYPE_JEWELRY, TYPE_LGD_COLORLESS, TYPE_LGD_FANCY, TYPE_LGD_ROUGH, TYPE_ROUGH } from '../constants/productTypes';
import { REFERENCE_IDS } from '../../common/constants/references';
import { formDiff } from '../../common/helpers/form';
import MediaCollection from '../../media/entity/MediaCollection';
import CustomGradingResult from '../../customGrading/entity/CustomGradingResult';
import Expenses from './Expenses/Expenses.ts';
import { ACLAssignee } from '@/security/entities/ACLAssignee';

export const READABLE_TYPES = {
  [TYPE_DIAMOND_COLORLESS]: 'Loose Diamond',
  [TYPE_DIAMOND_FANCY]: 'Loose Colored Diamond',
  [TYPE_LGD_COLORLESS]: 'Lab-Grown Diamond',
  [TYPE_LGD_FANCY]: 'Lab-Grown Colored Diamond',
  [TYPE_LGD_ROUGH]: 'Lab-Grown Rough Diamond',
  [TYPE_JEWELRY]: 'Jewelry Item',
  [TYPE_ROUGH]: 'Rough Diamond',
};

export default class Product {
  /**
   * @type {number}
   */
  id;

  /**
   * @type {string}
   */
  @observable
  createdAt;

  /**
   * @type {string}
   */
  @observable
  b2bSid;

  /**
   * @type {string}
   */
  @observable
  cid;

  /**
   * @type {int}
   */
  @observable
  price;

  /**
   * @type {number}
   */
  @observable
  carat;

  /**
   * @type {number}
   */
  @observable
  access;

  /**
   * @type {number}
   */
  @observable
  dataAccess;

  /**
   * @type {AbstractACLAssignee[]}
   */
  @observable
  acl = [];

  /**
   * @type {Object}
   */
  @observable
  cutShape;

  /**
   * @type {string}
   */
  @observable
  manufacturer;

  /**
   * @type {?{id: ?number, firstName: ?string, lastName: ?string, email: ?string, title: ?string, user: ?User}}
   */
  @observable
  seller;

  /**
   * @description Real B2B data (can be different to 'seller' field). Visible only to ADMIN role
   * @type {?{id: ?number, title: ?string}}
   */
  @observable.ref
  b2b;

  /**
   * @type {string}
   */
  @observable
  i3dmini;

  /**
   * @type {string}
   */
  @observable
  sellerNote;

  /**
   * @type {MediaCollection}
   */
  @observable
  mediaCollection;

  /**
   * @type {string}
   */
  @observable
  listed;

  /**
   * @type {string}
   */
  @observable
  shortView = '';

  /**
   * @type {number}
   */
  @observable
  _originalCarat;

  /**
   * @type {boolean}
   */
  needFullDetails;

  /**
   * @type {Object.<string, CustomGradingResult>}
   */
  @observable
  customGrades;

  /**
   * @type {boolean}
   */
  @observable
  isLabGrown;

  /**
   * @type {?Project}
   */
  @observable.ref
  project;

  /**
   * @type {?Solution}
   */
  @observable.ref
  solution;

  /**
   * @type {?string}
   */
  hpoGuid;

  /**
   * @type {?CommentModel}
   */
  @observable.ref
  comment;

  /**
   * @type {?string}
   */
  @observable.ref
  altSku;

  /**
   * @type {?DriveProjectSummary}
   */
  @observable.ref
  driveProject;

  /**
   * @type {TaskQueueModel[]}
   */
  @observable
  taskQueue = [];

  /**
   * @param {Object} modelData
   */
  constructor(modelData = {}) {
    this.populate(modelData);
  }

  /**
   * @return {number}
   */
  get priceCt() {
    let val = 0.0;
    if (this.price && this.carat) {
      val = Math.round((this.price / this.carat) * 100) / 100;
    }
    return val;
  }

  /**
   * @param {number} val
   */
  set priceCt(val) {
    if (!this.carat) {
      return;
    }
    this.price = Math.round(val * this.carat * 100) / 100;
  }

  /**
   * @type {Expenses}
   */
  @observable
  expenses;

  /**
   * @return {boolean}
   */
  @computed
  get isLoaded() {
    return Boolean(this.b2bSid);
  }

  /**
   * @return {boolean}
   */
  @computed
  get hasMedia() {
    return this.mediaCollection && this.mediaCollection.mediaItems && this.mediaCollection.mediaItems.length > 0;
  }

  /**
   * @return {?Media}
   */
  @computed
  get defaultMedia() {
    if (!this.hasMedia) {
      return null;
    }

    return this.mediaCollection.mediaItems[0];
  }

  /**
   * @return {string}
   */
  @computed
  get type() {
    return this.entityType;
  }

  /**
   * @return {?number}
   */
  @computed
  get sellerId() {
    if (!this.seller) {
      return null;
    }

    return this.seller.id;
  }

  /**
   * @return {?string}
   */
  @computed
  get sellerEmail() {
    return this.seller && this.seller.user && this.seller.user.email ? this.seller.user.email : null;
  }

  /**
   * @return {boolean}
   */
  @computed
  get isValid() {
    return true;
  }

  /**
   * @return {boolean}
   */
  @computed
  get isReference() {
    return REFERENCE_IDS.includes(this.id);
  }

  /**
   * @return {boolean}
   */
  @computed
  get isComparableWithReference() {
    return (this.type === TYPE_DIAMOND_COLORLESS || this.type === TYPE_LGD_COLORLESS) && !this.isReference;
  }

  /**
   * @param product Product
   * @return {boolean}
   */
  diff(product) {
    return formDiff(product, this);
  }

  /**
   * @param {?Project} project
   * @return {Product}
   */
  @action
  setProject(project) {
    this.project = project;

    return this;
  }

  /**
   * @param {?Solution} solution
   * @return {Product}
   */
  @action
  setSolution(solution) {
    this.solution = solution;

    return this;
  }

  /**
   * @param {Object|Product} modelData
   */
  populate(modelData) {
    if (modelData.fire) {
      delete modelData.fire;
    }

    Object.keys(modelData).forEach((propertyKey) => {
      if (typeof modelData[propertyKey] === 'function') {
        return;
      }

      if (propertyKey === 'mediaCollection' && !(modelData[propertyKey] instanceof MediaCollection)) {
        return;
      }

      this[propertyKey] = modelData[propertyKey];
    });

    if (this._embedded && this._embedded.shortView) {
      this.shortView = this._embedded.shortView;
    }

    if (this._embedded && this._embedded.b2b) {
      this.b2b = this._embedded.b2b;
    }

    if (this._embedded && this._embedded.i3dmini) {
      this.i3dmini = this._embedded.i3dmini;
    }

    if (this._embedded && Array.isArray(this._embedded.acl)) {
      this.acl = this._embedded.acl.map(acl => ({ ...acl, assignee: new ACLAssignee(acl.assignee) }));
    }

    if (this._embedded && this._embedded.driveProject) {
      this.driveProject = this._embedded.driveProject;
    }

    delete this._entityType;
    if (!this.entityType) {
      this.entityType = modelData._entityType;
    }

    if (modelData.mediaCollection && !(modelData.mediaCollection instanceof MediaCollection)) {
      this.mediaCollection = new MediaCollection(modelData.mediaCollection, this);
    }

    if (this._embedded && this._embedded.customGrades) {
      this.customGrades = {};

      Object.keys(this._embedded.customGrades).forEach((customGradeCode) => {
        this.customGrades[customGradeCode] = new CustomGradingResult({
          value: this._embedded.customGrades[customGradeCode].grade,
          customGradingCode: customGradeCode,
        });
      });
    }

    if (this._embedded && this._embedded.expenses) {
      this.expenses = new Expenses(this._embedded.expenses);
    }

    if (modelData.hpoGuid) {
      this.hpoGuid = modelData.hpoGuid;
    }

    if (this.comment) {
      this.comment = modelData.comment;
    }

    this.altSku = modelData.altSku;
  }

  /**
   * @return {?number}
   */
  getParentId() {
    const projectSummary = this.driveProject;

    if (!projectSummary?.project || projectSummary.suggest) {
      return null;
    }

    return projectSummary.project.id;
  }


  getMediaBySetupPreset(sp, size) {
    if (!this.mediaCollection) {
      return null;
    }

    const mediaCollection = this.mediaCollection.items.find((media) => media.sp === sp)

    if (!mediaCollection || mediaCollection.isEmpty) {
      return null;
    }

    let media = null;

    mediaCollection.mediaSources.forEach((source) => {
      if (typeof size === 'undefined') {
        media = source.mediaSubFiles[0]

        return;
      }

      source.mediaSubFiles.forEach((subFile) => {
        if (subFile.resolutionX === size) {
          media = subFile
        }
      })
    })

    return media
  }

  toJS() {
    return toJS({ ...this });
  }
}

export function addCollectionCommentsToProducts(products, collectionProducts) {
  const commentMap = new Map(collectionProducts.map(p => [p.id, p.comment]));
  return products.map((p) => {
    p.comment = commentMap.get(p.id);
    return p;
  });
}
