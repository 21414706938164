// extracted by mini-css-extract-plugin
var _1 = "base-link";
var _2 = "#76F366";
var _3 = "#EB8632";
var _4 = "#ECC834";
var _5 = "#21FC87";
var _6 = "#EA4A30";
var _7 = "#C7EA45";
var _8 = "#272B2B";
var _9 = "#BDBDBD";
var _a = "#7D7D7D";
var _b = "#6A0DAD";
var _c = "#3A65FF";
var _d = "#4A90E2";
var _e = "#ffffff";
var _f = "layout";
var _10 = "layoutOneColumn";
var _11 = "layoutStrictHeight";
var _12 = "layoutTwoColumn";
var _13 = "layoutWithSidebar";
var _14 = "layoutWithoutSidebar";
var _15 = "product-block-grid";
var _16 = "product-block-subgrid";
var _17 = "reset-button";
var _18 = "1200px";
var _19 = "992px";
var _1a = "1366px";
var _1b = "768px";
var _1c = "1475px";
var _1d = "576px";
var _1e = "1600px";
var _1f = "320px";
var _20 = "2500px";
var _21 = "single-page-responsive-font-size";
export { _1 as "base-link", _2 as "colorGradeEX", _3 as "colorGradeFR", _4 as "colorGradeGD", _5 as "colorGradeOU", _6 as "colorGradePR", _7 as "colorGradeVG", _8 as "colorNeutral", _9 as "colorNeutralLighest", _a as "colorNeutralLight", _b as "colorNonOwnerSweetLine", _c as "colorOwnerSweetLine", _d as "colorRegularLinks", _e as "colorWhite", _f as "layout", _10 as "layoutOneColumn", _11 as "layoutStrictHeight", _12 as "layoutTwoColumn", _13 as "layoutWithSidebar", _14 as "layoutWithoutSidebar", _15 as "product-block-grid", _16 as "product-block-subgrid", _17 as "reset-button", _18 as "screenlg", _19 as "screenmd", _1a as "screenml", _1b as "screensm", _1c as "screenxlg", _1d as "screenxs", _1e as "screenxxlg", _1f as "screenxxs", _20 as "screenxxxlg", _21 as "single-page-responsive-font-size" }
export default { "base-link": _1, "colorGradeEX": _2, "colorGradeFR": _3, "colorGradeGD": _4, "colorGradeOU": _5, "colorGradePR": _6, "colorGradeVG": _7, "colorNeutral": _8, "colorNeutralLighest": _9, "colorNeutralLight": _a, "colorNonOwnerSweetLine": _b, "colorOwnerSweetLine": _c, "colorRegularLinks": _d, "colorWhite": _e, "layout": _f, "layoutOneColumn": _10, "layoutStrictHeight": _11, "layoutTwoColumn": _12, "layoutWithSidebar": _13, "layoutWithoutSidebar": _14, "product-block-grid": _15, "product-block-subgrid": _16, "reset-button": _17, "screenlg": _18, "screenmd": _19, "screenml": _1a, "screensm": _1b, "screenxlg": _1c, "screenxs": _1d, "screenxxlg": _1e, "screenxxs": _1f, "screenxxxlg": _20, "single-page-responsive-font-size": _21 }
