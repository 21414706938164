// extracted by mini-css-extract-plugin
var _1 = "base-link";
var _2 = "#76F366";
var _3 = "#EB8632";
var _4 = "#ECC834";
var _5 = "#21FC87";
var _6 = "#EA4A30";
var _7 = "#C7EA45";
var _8 = "#272B2B";
var _9 = "#BDBDBD";
var _a = "#7D7D7D";
var _b = "#6A0DAD";
var _c = "#3A65FF";
var _d = "#4A90E2";
var _e = "#ffffff";
var _f = "modal";
var _10 = "modalBackdrop";
var _11 = "modalBottomed";
var _12 = "modalCentered";
var _13 = "modalChildren";
var _14 = "modalContent";
var _15 = "modalContentWithScroll";
var _16 = "modalContentWrapper";
var _17 = "modalControls";
var _18 = "modalFooter";
var _19 = "modalHeader";
var _1a = "modalInner";
var _1b = "modalInnerFitByContent";
var _1c = "modalInnerStretched";
var _1d = "modalInnerWide";
var _1e = "modalTitle";
var _1f = "product-block-grid";
var _20 = "product-block-subgrid";
var _21 = "reset-button";
var _22 = "1200px";
var _23 = "992px";
var _24 = "1366px";
var _25 = "768px";
var _26 = "1475px";
var _27 = "576px";
var _28 = "1600px";
var _29 = "320px";
var _2a = "2500px";
var _2b = "single-page-responsive-font-size";
export { _1 as "base-link", _2 as "colorGradeEX", _3 as "colorGradeFR", _4 as "colorGradeGD", _5 as "colorGradeOU", _6 as "colorGradePR", _7 as "colorGradeVG", _8 as "colorNeutral", _9 as "colorNeutralLighest", _a as "colorNeutralLight", _b as "colorNonOwnerSweetLine", _c as "colorOwnerSweetLine", _d as "colorRegularLinks", _e as "colorWhite", _f as "modal", _10 as "modalBackdrop", _11 as "modalBottomed", _12 as "modalCentered", _13 as "modalChildren", _14 as "modalContent", _15 as "modalContentWithScroll", _16 as "modalContentWrapper", _17 as "modalControls", _18 as "modalFooter", _19 as "modalHeader", _1a as "modalInner", _1b as "modalInnerFitByContent", _1c as "modalInnerStretched", _1d as "modalInnerWide", _1e as "modalTitle", _1f as "product-block-grid", _20 as "product-block-subgrid", _21 as "reset-button", _22 as "screenlg", _23 as "screenmd", _24 as "screenml", _25 as "screensm", _26 as "screenxlg", _27 as "screenxs", _28 as "screenxxlg", _29 as "screenxxs", _2a as "screenxxxlg", _2b as "single-page-responsive-font-size" }
export default { "base-link": _1, "colorGradeEX": _2, "colorGradeFR": _3, "colorGradeGD": _4, "colorGradeOU": _5, "colorGradePR": _6, "colorGradeVG": _7, "colorNeutral": _8, "colorNeutralLighest": _9, "colorNeutralLight": _a, "colorNonOwnerSweetLine": _b, "colorOwnerSweetLine": _c, "colorRegularLinks": _d, "colorWhite": _e, "modal": _f, "modalBackdrop": _10, "modalBottomed": _11, "modalCentered": _12, "modalChildren": _13, "modalContent": _14, "modalContentWithScroll": _15, "modalContentWrapper": _16, "modalControls": _17, "modalFooter": _18, "modalHeader": _19, "modalInner": _1a, "modalInnerFitByContent": _1b, "modalInnerStretched": _1c, "modalInnerWide": _1d, "modalTitle": _1e, "product-block-grid": _1f, "product-block-subgrid": _20, "reset-button": _21, "screenlg": _22, "screenmd": _23, "screenml": _24, "screensm": _25, "screenxlg": _26, "screenxs": _27, "screenxxlg": _28, "screenxxs": _29, "screenxxxlg": _2a, "single-page-responsive-font-size": _2b }
