export const KEY_USER = 'user';
export const KEY_AUTH_TOKEN = 'auth_token';
export const KEY_CONSTANTS = 'constants';
export const KEY_URI_AFTER_AUTH = 'uri-after-auth';
export const KEY_URI_AFTER_TOP_UP = 'uri-after-top-up';
export const KEY_WAS_LOGGED_OUT = 'was-logged-out';
export const KEY_IS_SIDEBAR_OPENED = 'is-sidebar-opened';
export const KEY_E2E = 'e2e';
export const KEY_VIEWER_PRESET_ID = 'viewer-preset-id';
export const KEY_STEREO_MODE = 'stereo-mode';
export const KEY_SHARE_TARGETS = 'share-targets';
export const KEY_DRIVE_ACCESS = 'drive-access';
export const KEY_NON_OWNER_SWEET_LINE = 'sweet-line';
export const KEY_DEBUG = 'debug';
/** @deprecated */
export const KEY_TOKEN = 'token';
/** @deprecated */
export const KEY_ACCESS_TOKEN = 'access-token';
/** @deprecated */
export const KEY_ACCESS_TOKEN_LEGACY = 'access_token';
export default class Storage {
    static get STORAGE_SESSION() {
        return window.sessionStorage;
    }
    static get STORAGE_LOCAL() {
        return window.localStorage;
    }
    // get object value from storage
    static getObject(key, storage) {
        if (!key || !storage) {
            return null;
        }
        let value = null;
        const json = storage.getItem(key);
        if (json) {
            try {
                value = JSON.parse(json);
            }
            catch (err) {
                value = json;
            }
        }
        return value;
    }
    // get object value from storage
    static hasObject(key, storage) {
        return Storage.getObject(key, storage) !== null;
    }
    // save object to storage
    static saveObject(key, content, storage) {
        if (!key || !storage) {
            return;
        }
        let value = content;
        if (typeof content !== 'string') {
            value = JSON.stringify(content);
        }
        storage.setItem(key, value);
    }
    // deletes object from storage
    static removeObject(key, storage) {
        if (!key || !storage) {
            return;
        }
        storage.removeItem(key);
    }
}
