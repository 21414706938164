var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { action, observable } from 'mobx';
import S3UploadAPI from '@/common/api/S3UploadAPI';
import useStore from '@/common/hooks/useStore';
import Notifier from '@/common/services/Notifier';
class UploadFileStore {
    isUploading = false;
    s3UploadAPI;
    constructor() {
        this.s3UploadAPI = new S3UploadAPI();
    }
    uploadImage = async (file) => {
        this.isUploading = true;
        try {
            const response = await this.s3UploadAPI.uploadImage(file);
            return response?.content?.url;
        }
        catch (e) {
            Notifier.error(e);
            throw e;
        }
        finally {
            this.isUploading = false;
        }
        return null;
    };
}
__decorate([
    observable
], UploadFileStore.prototype, "isUploading", void 0);
__decorate([
    action
], UploadFileStore.prototype, "uploadImage", void 0);
export const useUploadFileStore = () => useStore(UploadFileStore);
const uploadFileStore = new UploadFileStore();
export { UploadFileStore };
export default uploadFileStore;
