import { jsx as _jsx } from "react/jsx-runtime";
import { memo } from 'react';
import cls from 'classnames';
import styles from './Label.less';
export const SIZE_SMALL = 's';
export const SIZE_MEDIUM = 'm';
export const SIZE_LARGE = 'l';
export const SIZE_XLARGE = 'xl';
export const NEUTRAL = 'neutral';
export const PRIMARY = 'primary';
export const SECONDARY = 'secondary';
export const TERTIARY = 'tertiary';
export const QUATERNARY = 'quaternary';
export const ACCENT = 'accent';
export const WHITE = 'white';
export const NEUTRAL_LIGHT = 'neutralLight';
const CLASS_SIZE_MAP = {
    [SIZE_SMALL]: styles.labelS,
    [SIZE_MEDIUM]: styles.labelM,
    [SIZE_LARGE]: styles.labelL,
    [SIZE_XLARGE]: styles.labelXL,
};
const CLASS_APPEARANCE_MAP = {
    [NEUTRAL]: styles.labelNeutral,
    [PRIMARY]: styles.labelPrimary,
    [SECONDARY]: styles.labelSecondary,
    [TERTIARY]: styles.labelTertiary,
    [QUATERNARY]: styles.labelQuaternary,
    [ACCENT]: styles.labelAccent,
    [WHITE]: styles.labelWhite,
    [NEUTRAL_LIGHT]: styles.labelNeutralLight,
};
function Label(props) {
    const { className, appearance = NEUTRAL, rounded = true, size = SIZE_MEDIUM, block = false, ...rest } = props;
    return (_jsx("span", { className: cls(styles.label, `${CLASS_APPEARANCE_MAP[appearance]}`, `${CLASS_SIZE_MAP[size]}`, {
            [styles.labelRound]: Boolean(rounded),
            [styles.labelBlock]: Boolean(block),
        }, className), ...rest }));
}
export default memo(Label);
