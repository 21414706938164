import ToFormDataSerializationStrategy from '@/common/network/serialization/ToFormDataSerializationStrategy';
import CutwiseAPIClient, { METHOD_POST } from '../network/CutwiseAPIClient';
export const PATH_UPLOAD_IMAGE = 'api/upload/image';
export default class S3UploadAPI {
    uploadImage(file) {
        const qb = CutwiseAPIClient.createQueryBuilder()
            .method(METHOD_POST)
            .path(PATH_UPLOAD_IMAGE)
            .body({ auto: file })
            .serializer(ToFormDataSerializationStrategy)
            .suppressErrorNotifications();
        const query = qb.getQuery();
        return CutwiseAPIClient.sendQuery(query);
    }
}
