import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { confirmable } from 'react-confirm';
import Button, { DEFAULT } from '../../Button/Button';
import ButtonContainer from '../../ButtonContainer/ButtonContainer';
import Modal, { CENTER } from '../../Modal/Modal';
function Confirmation(props) {
    const { okLabel = 'OK', okColor = DEFAULT, cancelLabel = 'Cancel', cancelColor = DEFAULT, title, confirmation, show, proceed, dismiss, cancel, showCancelButton = true, showOkButton = true, } = props;
    function onConfirm() {
        proceed();
    }
    function onCancel() {
        cancel();
    }
    return (_jsx(Modal, { isShowed: show, onClose: dismiss, title: title, position: CENTER, renderModalFooter: () => (_jsxs(ButtonContainer, { children: [showCancelButton && (_jsx(Button, { color: cancelColor, onClick: onCancel, children: cancelLabel })), showOkButton && (_jsx(Button, { color: okColor, onClick: onConfirm, children: okLabel }))] })), children: confirmation }));
}
export default confirmable(Confirmation);
