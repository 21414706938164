// extracted by mini-css-extract-plugin
var _1 = "animatedDropdown";
var _2 = "animatedDropdownContent";
var _3 = "animatedDropdownContentClosed";
var _4 = "animatedDropdownLabel";
var _5 = "animatedDropdownLabelLeft";
var _6 = "animatedDropdownLabelOpened";
var _7 = "animatedDropdownMenuContainer";
var _8 = "animatedDropdownTriangle";
var _9 = "animatedDropdownTriangleOpened";
var _a = "base-link";
var _b = "#76F366";
var _c = "#EB8632";
var _d = "#ECC834";
var _e = "#21FC87";
var _f = "#EA4A30";
var _10 = "#C7EA45";
var _11 = "#272B2B";
var _12 = "#BDBDBD";
var _13 = "#7D7D7D";
var _14 = "#6A0DAD";
var _15 = "#3A65FF";
var _16 = "#4A90E2";
var _17 = "#ffffff";
var _18 = "product-block-grid";
var _19 = "product-block-subgrid";
var _1a = "reset-button";
var _1b = "1200px";
var _1c = "992px";
var _1d = "1366px";
var _1e = "768px";
var _1f = "1475px";
var _20 = "576px";
var _21 = "1600px";
var _22 = "320px";
var _23 = "2500px";
var _24 = "single-page-responsive-font-size";
export { _1 as "animatedDropdown", _2 as "animatedDropdownContent", _3 as "animatedDropdownContentClosed", _4 as "animatedDropdownLabel", _5 as "animatedDropdownLabelLeft", _6 as "animatedDropdownLabelOpened", _7 as "animatedDropdownMenuContainer", _8 as "animatedDropdownTriangle", _9 as "animatedDropdownTriangleOpened", _a as "base-link", _b as "colorGradeEX", _c as "colorGradeFR", _d as "colorGradeGD", _e as "colorGradeOU", _f as "colorGradePR", _10 as "colorGradeVG", _11 as "colorNeutral", _12 as "colorNeutralLighest", _13 as "colorNeutralLight", _14 as "colorNonOwnerSweetLine", _15 as "colorOwnerSweetLine", _16 as "colorRegularLinks", _17 as "colorWhite", _18 as "product-block-grid", _19 as "product-block-subgrid", _1a as "reset-button", _1b as "screenlg", _1c as "screenmd", _1d as "screenml", _1e as "screensm", _1f as "screenxlg", _20 as "screenxs", _21 as "screenxxlg", _22 as "screenxxs", _23 as "screenxxxlg", _24 as "single-page-responsive-font-size" }
export default { "animatedDropdown": _1, "animatedDropdownContent": _2, "animatedDropdownContentClosed": _3, "animatedDropdownLabel": _4, "animatedDropdownLabelLeft": _5, "animatedDropdownLabelOpened": _6, "animatedDropdownMenuContainer": _7, "animatedDropdownTriangle": _8, "animatedDropdownTriangleOpened": _9, "base-link": _a, "colorGradeEX": _b, "colorGradeFR": _c, "colorGradeGD": _d, "colorGradeOU": _e, "colorGradePR": _f, "colorGradeVG": _10, "colorNeutral": _11, "colorNeutralLighest": _12, "colorNeutralLight": _13, "colorNonOwnerSweetLine": _14, "colorOwnerSweetLine": _15, "colorRegularLinks": _16, "colorWhite": _17, "product-block-grid": _18, "product-block-subgrid": _19, "reset-button": _1a, "screenlg": _1b, "screenmd": _1c, "screenml": _1d, "screensm": _1e, "screenxlg": _1f, "screenxs": _20, "screenxxlg": _21, "screenxxs": _22, "screenxxxlg": _23, "single-page-responsive-font-size": _24 }
