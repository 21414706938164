import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useEffect, useRef, useState } from 'react';
import cls from 'classnames';
import { useMount, useToggle } from '@cutwise/utils';
import styles from './AnimatedDropdown.less';
export default function AnimatedDropdown(props) {
    const { className, label, children } = props;
    const contentRef = useRef(null);
    const [opened, , close, toggle] = useToggle();
    const [contentHeight, setContentHeight] = useState(0);
    const getContentHeight = useCallback(() => {
        const element = contentRef.current;
        return element ? element.scrollHeight + element.offsetHeight : 0;
    }, []);
    const handleClickOutside = useCallback((e) => {
        e.stopPropagation();
        close();
    }, []);
    const handleButtonClick = useCallback((e) => {
        e.stopPropagation();
        toggle();
    }, []);
    useEffect(() => {
        if (opened) {
            setContentHeight(getContentHeight());
            document.addEventListener('click', handleClickOutside);
        }
        else {
            document.removeEventListener('click', handleClickOutside);
        }
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [opened]);
    useMount(() => {
        setContentHeight(getContentHeight());
    });
    return (_jsxs("div", { className: cls(styles.animatedDropdown, className), children: [_jsxs("div", { className: `${styles.animatedDropdownLabel} ${opened ? styles.animatedDropdownLabelOpened : ''}`, onClick: handleButtonClick, role: "button", tabIndex: 0, children: [_jsx("div", { className: styles.animatedDropdownLabelLeft, children: label }), _jsx("span", { className: `${styles.animatedDropdownTriangle} ${opened ? styles.animatedDropdownTriangleOpened : ''}` })] }), _jsx("div", { className: `${styles.animatedDropdownContent} ${!opened ? styles.animatedDropdownContentClosed : ''}`, style: { maxHeight: contentHeight }, children: _jsx("div", { ref: contentRef, className: styles.animatedDropdownMenuContainer, onClick: close, children: children }) })] }));
}
