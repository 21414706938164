import { jsx as _jsx } from "react/jsx-runtime";
import { memo } from 'react';
import cls from 'classnames';
import styles from './Heading.module.less';
function Heading(props) {
    const { className, children, bright = true, uppercase = false, bold = false, appearance: HeadingComponent = 'h1' } = props;
    return (_jsx(HeadingComponent, { className: cls(styles.heading, {
            [styles.headingBright]: bright,
            [styles.headingUppercase]: uppercase,
            [styles.headingBold]: bold,
        }, className), children: children }));
}
export default memo(Heading);
