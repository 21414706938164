// extracted by mini-css-extract-plugin
var _1 = "active";
var _2 = "base-link";
var _3 = "#76F366";
var _4 = "#EB8632";
var _5 = "#ECC834";
var _6 = "#21FC87";
var _7 = "#EA4A30";
var _8 = "#C7EA45";
var _9 = "#272B2B";
var _a = "#BDBDBD";
var _b = "#7D7D7D";
var _c = "#6A0DAD";
var _d = "#3A65FF";
var _e = "#4A90E2";
var _f = "#ffffff";
var _10 = "header";
var _11 = "header--displayed";
var _12 = "header--hidden";
var _13 = "header-link";
var _14 = "header-link--desktop";
var _15 = "header-link--dropdown";
var _16 = "header-link__triangle";
var _17 = "header-link__triangle--opened";
var _18 = "headerContent";
var _19 = "headerFixed";
var _1a = "headerLinkActive";
var _1b = "headerLinkLGD";
var _1c = "headerProductTypeIcon";
var _1d = "headerProductTypeMenuItem";
var _1e = "headerProductTypeMenuWrapper";
var _1f = "header__cache-proxy";
var _20 = "header__chapters";
var _21 = "header__hm";
var _22 = "header__left";
var _23 = "header__link";
var _24 = "header__link--collections";
var _25 = "header__link--desktop";
var _26 = "header__link--disabled";
var _27 = "header__link--icon";
var _28 = "header__link-title";
var _29 = "header__links";
var _2a = "header__logo";
var _2b = "header__mobile";
var _2c = "header__mobile--opened";
var _2d = "header__mobile-item";
var _2e = "header__mobile-item-list";
var _2f = "hide-header";
var _30 = "hide-on-small-screens";
var _31 = "iconFancy";
var _32 = "label";
var _33 = "pinned-collection";
var _34 = "pinned-collection__label";
var _35 = "pinned-collection__link";
var _36 = "popover-container";
var _37 = "product-block-grid";
var _38 = "product-block-subgrid";
var _39 = "reset-button";
var _3a = "1200px";
var _3b = "992px";
var _3c = "1366px";
var _3d = "768px";
var _3e = "1475px";
var _3f = "576px";
var _40 = "1600px";
var _41 = "320px";
var _42 = "2500px";
var _43 = "show-header";
var _44 = "show-on-small-screens";
var _45 = "single-page-responsive-font-size";
export { _1 as "active", _2 as "base-link", _3 as "colorGradeEX", _4 as "colorGradeFR", _5 as "colorGradeGD", _6 as "colorGradeOU", _7 as "colorGradePR", _8 as "colorGradeVG", _9 as "colorNeutral", _a as "colorNeutralLighest", _b as "colorNeutralLight", _c as "colorNonOwnerSweetLine", _d as "colorOwnerSweetLine", _e as "colorRegularLinks", _f as "colorWhite", _10 as "header", _11 as "header--displayed", _12 as "header--hidden", _13 as "header-link", _14 as "header-link--desktop", _15 as "header-link--dropdown", _16 as "header-link__triangle", _17 as "header-link__triangle--opened", _18 as "headerContent", _19 as "headerFixed", _1a as "headerLinkActive", _1b as "headerLinkLGD", _1c as "headerProductTypeIcon", _1d as "headerProductTypeMenuItem", _1e as "headerProductTypeMenuWrapper", _1f as "header__cache-proxy", _20 as "header__chapters", _21 as "header__hm", _22 as "header__left", _23 as "header__link", _24 as "header__link--collections", _25 as "header__link--desktop", _26 as "header__link--disabled", _27 as "header__link--icon", _28 as "header__link-title", _29 as "header__links", _2a as "header__logo", _2b as "header__mobile", _2c as "header__mobile--opened", _2d as "header__mobile-item", _2e as "header__mobile-item-list", _2f as "hide-header", _30 as "hide-on-small-screens", _31 as "iconFancy", _32 as "label", _33 as "pinned-collection", _34 as "pinned-collection__label", _35 as "pinned-collection__link", _36 as "popover-container", _37 as "product-block-grid", _38 as "product-block-subgrid", _39 as "reset-button", _3a as "screenlg", _3b as "screenmd", _3c as "screenml", _3d as "screensm", _3e as "screenxlg", _3f as "screenxs", _40 as "screenxxlg", _41 as "screenxxs", _42 as "screenxxxlg", _43 as "show-header", _44 as "show-on-small-screens", _45 as "single-page-responsive-font-size" }
export default { "active": _1, "base-link": _2, "colorGradeEX": _3, "colorGradeFR": _4, "colorGradeGD": _5, "colorGradeOU": _6, "colorGradePR": _7, "colorGradeVG": _8, "colorNeutral": _9, "colorNeutralLighest": _a, "colorNeutralLight": _b, "colorNonOwnerSweetLine": _c, "colorOwnerSweetLine": _d, "colorRegularLinks": _e, "colorWhite": _f, "header": _10, "header--displayed": _11, "header--hidden": _12, "header-link": _13, "header-link--desktop": _14, "header-link--dropdown": _15, "header-link__triangle": _16, "header-link__triangle--opened": _17, "headerContent": _18, "headerFixed": _19, "headerLinkActive": _1a, "headerLinkLGD": _1b, "headerProductTypeIcon": _1c, "headerProductTypeMenuItem": _1d, "headerProductTypeMenuWrapper": _1e, "header__cache-proxy": _1f, "header__chapters": _20, "header__hm": _21, "header__left": _22, "header__link": _23, "header__link--collections": _24, "header__link--desktop": _25, "header__link--disabled": _26, "header__link--icon": _27, "header__link-title": _28, "header__links": _29, "header__logo": _2a, "header__mobile": _2b, "header__mobile--opened": _2c, "header__mobile-item": _2d, "header__mobile-item-list": _2e, "hide-header": _2f, "hide-on-small-screens": _30, "iconFancy": _31, "label": _32, "pinned-collection": _33, "pinned-collection__label": _34, "pinned-collection__link": _35, "popover-container": _36, "product-block-grid": _37, "product-block-subgrid": _38, "reset-button": _39, "screenlg": _3a, "screenmd": _3b, "screenml": _3c, "screensm": _3d, "screenxlg": _3e, "screenxs": _3f, "screenxxlg": _40, "screenxxs": _41, "screenxxxlg": _42, "show-header": _43, "show-on-small-screens": _44, "single-page-responsive-font-size": _45 }
