import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback } from 'react';
import { observer } from 'mobx-react';
import { useToggle } from '@cutwise/utils';
import CollectionPopover from '@/collection/components/CollectionPopover/CollectionPopover';
import { useCollectionsStore } from '@/collection/stores/СollectionsStore.hook';
import Button from '@/common/components/Button/Button';
import Icon from '@/common/components/Icon/Icon';
import Label, { SIZE_MEDIUM, TERTIARY } from '@/common/components/Label/Label';
import Link, { NONE } from '@/common/components/Link/Link';
import URLProvider from '@/common/services/URLProvider';
import Popover from '../../Popover/Popover';
function CollectionNavItem() {
    const [isOpen, open, close] = useToggle();
    const { activeCollection, toggleActive } = useCollectionsStore();
    const stopPropagation = useCallback((e) => {
        e.stopPropagation();
    }, []);
    const handlePinClick = useCallback((e, c) => {
        e.preventDefault();
        e.stopPropagation();
        toggleActive(c);
    }, [toggleActive]);
    return (_jsxs(React.Fragment, { children: [activeCollection && (_jsxs("div", { "aria-live": "assertive", className: "pinned-collection", children: [_jsx("span", { className: "pinned-collection__label", children: "Pinned Collection:" }), _jsx(Link, { appearance: NONE, className: "pinned-collection__link", onClick: stopPropagation, to: URLProvider.collectionDetailPage(activeCollection), children: activeCollection.title }), _jsx(Button, { active: true, onClick: (e) => handlePinClick(e, activeCollection), size: "xs", withIcon: true, children: _jsx(Icon, { name: "pin-on" }) })] })), _jsx(Popover, { body: _jsx(CollectionPopover, { withLinks: true }), className: "Popover--transparent", isOpen: isOpen, onOuterAction: close, preferPlace: "below", tipSize: 0, children: _jsxs("span", { "aria-label": "Collection modal toggler", className: "header__link header__link--icon header__link--collections", onClick: open, children: [_jsx(Icon, { name: "like" }), activeCollection && activeCollection?.products.length > 0 && (_jsx(Label, { appearance: TERTIARY, "aria-label": "Number of products in pinned collection", "aria-live": "assertive", rounded: true, size: SIZE_MEDIUM, children: activeCollection.products.length }))] }) })] }));
}
export default observer(CollectionNavItem);
