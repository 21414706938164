import {
  TYPE_DIAMOND,
  TYPE_DIAMOND_COLORLESS,
  TYPE_DIAMOND_FANCY, TYPE_LGD,
  TYPE_LGD_COLORLESS,
  TYPE_LGD_FANCY,
} from '../constants/productTypes';
import SingleResponse from './SingleResponse';
import CollectionResponse from './CollectionResponse';
import {SECURITY_CHECK_ACL} from '../../security/constants/securityCheck';
import { HEADER_X_EXPENSES, HEADER_X_NEXT_PAYMENT, HEADER_X_TOTAL_COUNT } from '../../common/network/CutwiseAPIClient';

// This storage is deprecated and should be deleted
// its cache is not used now (data is added to cache, but never read. In past cache work with updateAt field from backend which is unreliable and should not be used).
// the only reason of its existence - it was hard to delete it during release.
// Task - https://octonus-teams.com/jira/browse/CUT-8585
/**
 * @deprecated
 */
export default class ProductRepository {
  promiseCache = {};

  /**
   * @param {ProductAPI} productAPI
   * @param {IdentityMap} storage
   */
  constructor(productAPI, storage) {
    this.storage = storage;
    this.productAPI = productAPI;
  }

  /**
   * @param {number} id
   * @param {string} productType
   * @param {boolean} shouldDumpResponse
   * @return {Promise}
   */
  getOne(id, productType = TYPE_DIAMOND_COLORLESS, shouldDumpResponse = false) {
    this.productAPI.updatePath(productType);

    return this.productAPI.fetchItem(id, shouldDumpResponse).then((res) => {
      const product = res.data;
      this.storage.add(product.id, product);
      this.promiseCache[id] = null;

      return new SingleResponse(product);
    });
  }

  /**
   * @param {Product} product
   * @return {Promise<Product|null>}
   */
  getFullData(product) {
    this.productAPI.updatePath(product.type);

    let promise;

    if (this.promiseCache[product.id]) {
      promise = this.promiseCache[product.id];
    } else {
      promise = this.productAPI.fetchItem(product.id, false);
      this.promiseCache[product.id] = promise;
    }

    return promise.then((res) => {
      const fetchedProduct = res.content;
      this.storage.add(product.id, fetchedProduct);
      product.populate(this.storage.get(product.id));
      this.promiseCache[product.id] = null;

      return product;
    });
  }

  /**
   * @param {number[]} ids
   * @param {?Sort} sort
   * @param {?boolean} indexOnly
   * @param {?string} scope
   * @param {?string} productType
   * @return {Promise<CollectionResponse>}
   */
  getByIds(ids, sort = null, indexOnly = null, scope = null, productType = null) {
    this.productAPI.updatePath(productType);

    let isLabGrown = null;
    if (productType === TYPE_LGD || productType === TYPE_LGD_COLORLESS || productType === TYPE_LGD_FANCY) {
      isLabGrown = true;
    }
    if (productType === TYPE_DIAMOND || productType === TYPE_DIAMOND_COLORLESS || productType === TYPE_DIAMOND_FANCY) {
      isLabGrown = false;
    }

    if (indexOnly === true) {
      return this.productAPI.fetchProductsById(ids, isLabGrown, sort, indexOnly, scope);
    }

    if (!ids || ids.length === 0) {
      return Promise.resolve(new CollectionResponse([], ids.length));
    }

    return this.productAPI.fetchProductsById(ids, isLabGrown, sort, indexOnly, scope).then((products) => {
      const fetchedProducts = products && Array.isArray(products) ? products : [];
      fetchedProducts.forEach((p) => {
        this.storage.add(p.id, p);
      });

      const resultedProducts = sort ? fetchedProducts : ids.map(id => this.storage.get(id)).filter(p => Boolean(p));

      this.storage.clear();

      return new CollectionResponse(resultedProducts, ids.length);
    });
  }

  /**
   * @param {?Filters} filters
   * @param {?Sort} sort
   * @param {?number} offset
   * @param {?number} limit
   * @param {?boolean} indexOnly
   * @param {?string} productType
   * @param {?number} b2bId
   * @param {?string} securityCheck
   * @return {Promise}
   */
  getByFilters(filters = null, sort = null, offset = null, limit = null, indexOnly = false, productType = TYPE_DIAMOND_COLORLESS, b2bId = null, securityCheck = SECURITY_CHECK_ACL) {
    this.productAPI.updatePath(productType);
    this.productAPI.b2bContext = b2bId;

    if (indexOnly === true) {
      return this.productAPI.fetchProductsByFilters(filters, sort, offset, limit, indexOnly, securityCheck);
    }

    // todo https://developer.mozilla.org/en-US/docs/Web/API/AbortController
    return this.productAPI.fetchProductsByFilters(filters, sort, offset, limit, false, securityCheck).then((res) => {
      const products = res.content
        ? res.content.map((d) => {
          // eslint-disable-next-line new-cap
          const product = new this.productAPI.entityConstructor(d);
          product.needFullDetails = false;

          this.storage.add(product.id, product);

          return product;
        })
        : [];

      this.storage.clear();

      return new CollectionResponse(
        products,
        res.networkResponse.headers.get(HEADER_X_TOTAL_COUNT),
        res.networkResponse.headers.get(HEADER_X_EXPENSES),
        res.networkResponse.headers.get(HEADER_X_NEXT_PAYMENT),
      );
    });
  }

  /**
   * @param {number} id
   * @param {Product} product
   */
  add(id, product) {
    this.storage.add(id, product);
  }

  /**
   * @param {Product} product
   */
  invalidate(product) {
    this.storage.remove(product.id);
  }
}
