// extracted by mini-css-extract-plugin
var _1 = "base-link";
var _2 = "#76F366";
var _3 = "#EB8632";
var _4 = "#ECC834";
var _5 = "#21FC87";
var _6 = "#EA4A30";
var _7 = "#C7EA45";
var _8 = "#272B2B";
var _9 = "#BDBDBD";
var _a = "#7D7D7D";
var _b = "#6A0DAD";
var _c = "#3A65FF";
var _d = "#4A90E2";
var _e = "#ffffff";
var _f = "product-block-grid";
var _10 = "product-block-subgrid";
var _11 = "productInfo";
var _12 = "productInfoDesc";
var _13 = "productInfoDescRight";
var _14 = "productInfoShortView";
var _15 = "productInfoSmall";
var _16 = "reset-button";
var _17 = "1200px";
var _18 = "992px";
var _19 = "1366px";
var _1a = "768px";
var _1b = "1475px";
var _1c = "576px";
var _1d = "1600px";
var _1e = "320px";
var _1f = "2500px";
var _20 = "single-page-responsive-font-size";
export { _1 as "base-link", _2 as "colorGradeEX", _3 as "colorGradeFR", _4 as "colorGradeGD", _5 as "colorGradeOU", _6 as "colorGradePR", _7 as "colorGradeVG", _8 as "colorNeutral", _9 as "colorNeutralLighest", _a as "colorNeutralLight", _b as "colorNonOwnerSweetLine", _c as "colorOwnerSweetLine", _d as "colorRegularLinks", _e as "colorWhite", _f as "product-block-grid", _10 as "product-block-subgrid", _11 as "productInfo", _12 as "productInfoDesc", _13 as "productInfoDescRight", _14 as "productInfoShortView", _15 as "productInfoSmall", _16 as "reset-button", _17 as "screenlg", _18 as "screenmd", _19 as "screenml", _1a as "screensm", _1b as "screenxlg", _1c as "screenxs", _1d as "screenxxlg", _1e as "screenxxs", _1f as "screenxxxlg", _20 as "single-page-responsive-font-size" }
export default { "base-link": _1, "colorGradeEX": _2, "colorGradeFR": _3, "colorGradeGD": _4, "colorGradeOU": _5, "colorGradePR": _6, "colorGradeVG": _7, "colorNeutral": _8, "colorNeutralLighest": _9, "colorNeutralLight": _a, "colorNonOwnerSweetLine": _b, "colorOwnerSweetLine": _c, "colorRegularLinks": _d, "colorWhite": _e, "product-block-grid": _f, "product-block-subgrid": _10, "productInfo": _11, "productInfoDesc": _12, "productInfoDescRight": _13, "productInfoShortView": _14, "productInfoSmall": _15, "reset-button": _16, "screenlg": _17, "screenmd": _18, "screenml": _19, "screensm": _1a, "screenxlg": _1b, "screenxs": _1c, "screenxxlg": _1d, "screenxxs": _1e, "screenxxxlg": _1f, "single-page-responsive-font-size": _20 }
