// extracted by mini-css-extract-plugin
var _1 = "base-link";
var _2 = "#76F366";
var _3 = "#EB8632";
var _4 = "#ECC834";
var _5 = "#21FC87";
var _6 = "#EA4A30";
var _7 = "#C7EA45";
var _8 = "#272B2B";
var _9 = "#BDBDBD";
var _a = "#7D7D7D";
var _b = "#6A0DAD";
var _c = "#3A65FF";
var _d = "#4A90E2";
var _e = "#ffffff";
var _f = "patentList";
var _10 = "patentListItem";
var _11 = "patentListItemCountry";
var _12 = "product-block-grid";
var _13 = "product-block-subgrid";
var _14 = "reset-button";
var _15 = "1200px";
var _16 = "992px";
var _17 = "1366px";
var _18 = "768px";
var _19 = "1475px";
var _1a = "576px";
var _1b = "1600px";
var _1c = "320px";
var _1d = "2500px";
var _1e = "single-page-responsive-font-size";
export { _1 as "base-link", _2 as "colorGradeEX", _3 as "colorGradeFR", _4 as "colorGradeGD", _5 as "colorGradeOU", _6 as "colorGradePR", _7 as "colorGradeVG", _8 as "colorNeutral", _9 as "colorNeutralLighest", _a as "colorNeutralLight", _b as "colorNonOwnerSweetLine", _c as "colorOwnerSweetLine", _d as "colorRegularLinks", _e as "colorWhite", _f as "patentList", _10 as "patentListItem", _11 as "patentListItemCountry", _12 as "product-block-grid", _13 as "product-block-subgrid", _14 as "reset-button", _15 as "screenlg", _16 as "screenmd", _17 as "screenml", _18 as "screensm", _19 as "screenxlg", _1a as "screenxs", _1b as "screenxxlg", _1c as "screenxxs", _1d as "screenxxxlg", _1e as "single-page-responsive-font-size" }
export default { "base-link": _1, "colorGradeEX": _2, "colorGradeFR": _3, "colorGradeGD": _4, "colorGradeOU": _5, "colorGradePR": _6, "colorGradeVG": _7, "colorNeutral": _8, "colorNeutralLighest": _9, "colorNeutralLight": _a, "colorNonOwnerSweetLine": _b, "colorOwnerSweetLine": _c, "colorRegularLinks": _d, "colorWhite": _e, "patentList": _f, "patentListItem": _10, "patentListItemCountry": _11, "product-block-grid": _12, "product-block-subgrid": _13, "reset-button": _14, "screenlg": _15, "screenmd": _16, "screenml": _17, "screensm": _18, "screenxlg": _19, "screenxs": _1a, "screenxxlg": _1b, "screenxxs": _1c, "screenxxxlg": _1d, "single-page-responsive-font-size": _1e }
