// extracted by mini-css-extract-plugin
var _1 = "#76F366";
var _2 = "#EB8632";
var _3 = "#ECC834";
var _4 = "#21FC87";
var _5 = "#EA4A30";
var _6 = "#C7EA45";
var _7 = "#272B2B";
var _8 = "#BDBDBD";
var _9 = "#7D7D7D";
var _a = "#6A0DAD";
var _b = "#3A65FF";
var _c = "#4A90E2";
var _d = "#ffffff";
var _e = "1200px";
var _f = "992px";
var _10 = "1366px";
var _11 = "768px";
var _12 = "1475px";
var _13 = "576px";
var _14 = "1600px";
var _15 = "320px";
var _16 = "2500px";
export { _1 as "colorGradeEX", _2 as "colorGradeFR", _3 as "colorGradeGD", _4 as "colorGradeOU", _5 as "colorGradePR", _6 as "colorGradeVG", _7 as "colorNeutral", _8 as "colorNeutralLighest", _9 as "colorNeutralLight", _a as "colorNonOwnerSweetLine", _b as "colorOwnerSweetLine", _c as "colorRegularLinks", _d as "colorWhite", _e as "screenlg", _f as "screenmd", _10 as "screenml", _11 as "screensm", _12 as "screenxlg", _13 as "screenxs", _14 as "screenxxlg", _15 as "screenxxs", _16 as "screenxxxlg" }
export default { "colorGradeEX": _1, "colorGradeFR": _2, "colorGradeGD": _3, "colorGradeOU": _4, "colorGradePR": _5, "colorGradeVG": _6, "colorNeutral": _7, "colorNeutralLighest": _8, "colorNeutralLight": _9, "colorNonOwnerSweetLine": _a, "colorOwnerSweetLine": _b, "colorRegularLinks": _c, "colorWhite": _d, "screenlg": _e, "screenmd": _f, "screenml": _10, "screensm": _11, "screenxlg": _12, "screenxs": _13, "screenxxlg": _14, "screenxxs": _15, "screenxxxlg": _16 }
