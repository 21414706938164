import { HEADER_X_NETBOX, HEADER_X_NETBOX_HOSTNAME, NETBOX_HOSTNAME, NETBOX_KEY, } from '@/common/network/CutwiseAPIClient';
import Storage from '@/common/services/Storage';
export default function DetectNetbox(query) {
    const netBoxVersion = query.result.networkResponse?.headers?.get(HEADER_X_NETBOX);
    if (netBoxVersion) {
        const netBoxHostname = query.result.networkResponse?.headers?.get(HEADER_X_NETBOX_HOSTNAME);
        Storage.saveObject(NETBOX_KEY, netBoxVersion, Storage.STORAGE_LOCAL);
        if (netBoxHostname) {
            Storage.saveObject(NETBOX_HOSTNAME, netBoxHostname, Storage.STORAGE_LOCAL);
        }
    }
    return query;
}
