export function addPrefixToHost(prefix) {
    let url = ``;
    /*
     * Below we need to check if site opened on subdomain (e.g. develop.cutwise.com, staging.cutwise.com),
     * if Yes, prefix should be like "api-*.cutwise.com", if No - "api.cutwise.com"
     *
     * This is because we can't have domain with 4 levels because of certificates
     *
     * Examples
     *   - cutwise.com -> api.cutwise.com
     *   - develop.cutwise.com -> api-develop.cutwise.com
     *   - staging.cutwise.com -> api-staging.cutwise.com
     * */
    const subdomainSearch = /\w+\.cutwise\.(com|local|link)/gi.exec(window.location.hostname);
    const subdomain = subdomainSearch?.[0] ?? null;
    url = window.location.protocol;
    url += `//${subdomain ? `${prefix}-${subdomain}` : `${prefix}.${window.location.hostname}`}`;
    return url;
}
