import { TYPE_DIAMOND, TYPE_DIAMOND_COLORLESS, TYPE_JEWELRY, TYPE_ROUGH } from '@/product/constants/productTypes';
export var CollectionType;
(function (CollectionType) {
    CollectionType[CollectionType["Diamond"] = 1] = "Diamond";
    CollectionType[CollectionType["Jewelry"] = 2] = "Jewelry";
    CollectionType[CollectionType["Rough"] = 3] = "Rough";
})(CollectionType || (CollectionType = {}));
export function toProductType(collectionType) {
    if (collectionType === CollectionType.Jewelry) {
        return TYPE_JEWELRY;
    }
    if (collectionType === CollectionType.Rough) {
        return TYPE_ROUGH;
    }
    return TYPE_DIAMOND;
}
// function extracted from old CollectionDetailsStore.updateProductTypeContext method
// and used for same logic in SurveyStore.
// converting to TYPE_DIAMOND_COLORLESS instead of TYPE_DIAMOND looks like mistype,
// but left as-is because of legacy
export function toAppStoreProductType(collectionType) {
    if (collectionType === CollectionType.Jewelry) {
        return TYPE_JEWELRY;
    }
    if (collectionType === CollectionType.Rough) {
        return TYPE_ROUGH;
    }
    return TYPE_DIAMOND_COLORLESS;
}
