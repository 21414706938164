// extracted by mini-css-extract-plugin
var _1 = "label";
var _2 = "labelAccent";
var _3 = "labelBlock";
var _4 = "labelL";
var _5 = "labelM";
var _6 = "labelNeutral";
var _7 = "labelNeutralLight";
var _8 = "labelPrimary";
var _9 = "labelQuaternary";
var _a = "labelRound";
var _b = "labelS";
var _c = "labelSecondary";
var _d = "labelTertiary";
var _e = "labelWhite";
var _f = "labelXL";
export { _1 as "label", _2 as "labelAccent", _3 as "labelBlock", _4 as "labelL", _5 as "labelM", _6 as "labelNeutral", _7 as "labelNeutralLight", _8 as "labelPrimary", _9 as "labelQuaternary", _a as "labelRound", _b as "labelS", _c as "labelSecondary", _d as "labelTertiary", _e as "labelWhite", _f as "labelXL" }
export default { "label": _1, "labelAccent": _2, "labelBlock": _3, "labelL": _4, "labelM": _5, "labelNeutral": _6, "labelNeutralLight": _7, "labelPrimary": _8, "labelQuaternary": _9, "labelRound": _a, "labelS": _b, "labelSecondary": _c, "labelTertiary": _d, "labelWhite": _e, "labelXL": _f }
