var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { action, autorun, computed, observable } from 'mobx';
import Collection from '@/collection/entity/Collection';
import useStore from '@/common/hooks/useStore';
import { NETBOX_HOSTNAME, NETBOX_KEY } from '@/common/network/CutwiseAPIClient';
import { NETBOX, securityManager } from '@/common/services/SecurityManager';
import Storage from '@/common/services/Storage';
import Project from '@/hpo/entity/Project';
import { BRIGHTFIELD, DARKFIELD, FIRE, I_ARROWS, I_ASET_BLACK, I_ASET_BLACK_TABLEDOWN, I_ASET_WHITE, I_BRIGHTFIELD, I_DARKFIELD, I_FACEUP_FLUOR, I_FIRE, I_HEARTS, I_OFFICE, I_PAVCOLOR_LAB, I_SIDE_FLUOR, I_SIDE_FLUOR_DARK, I_TCOLOR_LAB, I_TCOLOR_OUTDOOR, I_TCOLOR_UVFREE, I_WHITEDOME, LP_ASET_BLACK, LP_FACEUP_FLUOR, LP_FIRE, LP_OFFICE, LP_PAVCOLOR_LAB, LP_SIDE_FLUOR, LP_TCOLOR_LAB, OFFICE, } from '@/media/entity/SetupPreset';
import { CONNECT_TIMEOUT, NETBOX_DOMAIN, NETBOX_ORIGIN } from '@/netbox/constants/network';
import Product from '@/product/entity/Product';
import { NetboxStatusEvent } from '../constants/events';
import { NetboxStatus } from '../constants/statuses';
import ProxyCache from '../services/ProxyCache';
export default class NetBoxStore {
    status = NetboxStatus.Disabled;
    isServiceWorkerActivated = false;
    tasks = [];
    constructor() {
        this.isServiceWorkerActivated = Boolean(navigator.serviceWorker?.controller);
        if (!navigator.serviceWorker) {
            return;
        }
        navigator.serviceWorker.addEventListener('message', (event) => {
            if (event.data.code === NetboxStatusEvent.Init) {
                this.isServiceWorkerActivated = true;
            }
            if (event.data.code === NetboxStatusEvent.Get) {
                this.sendNetBoxStatusToServiceWorker();
            }
        });
        autorun(this.sendNetBoxStatusToServiceWorker);
    }
    get isAccessible() {
        return this.status !== NetboxStatus.Disabled;
    }
    get isSaveToNetBoxAvailable() {
        return (this.status !== NetboxStatus.Disabled ||
            securityManager.isGranted(NETBOX) ||
            !Storage.getObject(NETBOX_KEY, Storage.STORAGE_LOCAL));
    }
    get isNetBoxActive() {
        return this.status === NetboxStatus.Active || !!Storage.getObject(NETBOX_KEY, Storage.STORAGE_LOCAL) || window.location.origin.includes(NETBOX_ORIGIN);
    }
    sendNetBoxStatusToServiceWorker = () => {
        if (!this.isServiceWorkerActivated || !navigator.serviceWorker?.controller) {
            return;
        }
        navigator.serviceWorker.controller.postMessage({ code: NetboxStatusEvent.Send, status: this.status });
    };
    connectToNetBox = () => {
        const controller = new AbortController();
        const timeout = setTimeout(() => controller.abort(), CONNECT_TIMEOUT);
        fetch(`${window.location.protocol}//${NETBOX_ORIGIN}`, { signal: controller.signal })
            .then((response) => {
            if (response.ok) {
                Storage.saveObject(NETBOX_KEY, '2.0', Storage.STORAGE_LOCAL);
                Storage.saveObject(NETBOX_HOSTNAME, NETBOX_ORIGIN, Storage.STORAGE_LOCAL);
                this.status = NetboxStatus.Active;
            }
        })
            .catch(() => {
            Storage.removeObject(NETBOX_KEY, Storage.STORAGE_LOCAL);
            Storage.removeObject(NETBOX_HOSTNAME, Storage.STORAGE_LOCAL);
            this.status = NetboxStatus.Disabled;
        })
            .finally(() => {
            clearTimeout(timeout);
        });
    };
    save(entity) {
        let promise = null;
        if (entity instanceof Product) {
            promise = ProxyCache.saveProducts([entity], `${entity.b2bSid}, ${entity.shortView}`, [
                160,
                320,
                480,
                640,
                960,
                1200,
                1920,
            ]);
        }
        if (entity instanceof Collection || entity instanceof Project) {
            if (entity.products) {
                promise = ProxyCache.saveProducts(entity.products, entity.title, [160, 320, 480, 640, 960], [
                    I_HEARTS,
                    I_ARROWS,
                    I_ASET_WHITE,
                    I_ASET_BLACK,
                    I_ASET_BLACK_TABLEDOWN,
                    I_WHITEDOME,
                    I_BRIGHTFIELD,
                    I_TCOLOR_LAB,
                    I_PAVCOLOR_LAB,
                    I_TCOLOR_OUTDOOR,
                    I_TCOLOR_UVFREE,
                    I_SIDE_FLUOR,
                    I_SIDE_FLUOR_DARK,
                    I_FACEUP_FLUOR,
                    I_DARKFIELD,
                    I_OFFICE,
                    I_FIRE,
                    LP_ASET_BLACK,
                    LP_SIDE_FLUOR,
                    LP_FACEUP_FLUOR,
                    LP_OFFICE,
                    LP_FIRE,
                    LP_TCOLOR_LAB,
                    LP_PAVCOLOR_LAB,
                    DARKFIELD,
                    OFFICE,
                    FIRE,
                    BRIGHTFIELD,
                ]);
            }
        }
        if (!promise) {
            return null;
        }
        return promise.then((loadingTask) => {
            if (loadingTask) {
                this.tasks.push(loadingTask);
            }
            return loadingTask;
        });
    }
    waitForLoadingComplete(id) {
        return new Promise((resolve) => {
            const intervalId = setInterval(() => {
                fetch(`${NETBOX_DOMAIN}/cache/task/${id}`)
                    .then((response) => response.json())
                    .then(action((taskResponse) => {
                    if (!taskResponse || taskResponse.done === taskResponse.total) {
                        // task was removed from server and that mean that in was fully completed
                        clearInterval(intervalId);
                        resolve();
                    }
                    if (taskResponse) {
                        const task = this.tasks.find((t) => t.id === taskResponse.id);
                        if (task) {
                            task.done = taskResponse.done;
                        }
                    }
                }));
            }, 1000);
        });
    }
    dispose = () => {
        Storage.removeObject(NETBOX_KEY, Storage.STORAGE_LOCAL);
        Storage.removeObject(NETBOX_HOSTNAME, Storage.STORAGE_LOCAL);
        this.status = NetboxStatus.Disabled;
    };
}
__decorate([
    observable
], NetBoxStore.prototype, "status", void 0);
__decorate([
    observable
], NetBoxStore.prototype, "isServiceWorkerActivated", void 0);
__decorate([
    observable
], NetBoxStore.prototype, "tasks", void 0);
__decorate([
    computed
], NetBoxStore.prototype, "isAccessible", null);
__decorate([
    computed
], NetBoxStore.prototype, "isSaveToNetBoxAvailable", null);
__decorate([
    computed
], NetBoxStore.prototype, "isNetBoxActive", null);
export const useNetBoxStore = () => useStore(NetBoxStore);
export const netBoxStore = new NetBoxStore();
