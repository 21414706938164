// extracted by mini-css-extract-plugin
var _1 = "qjPlGcJnewAsQIugpoqM";
var _2 = "upFU284hr7vbVkZL2qwY";
var _3 = "#76F366";
var _4 = "#EB8632";
var _5 = "#ECC834";
var _6 = "#21FC87";
var _7 = "#EA4A30";
var _8 = "#C7EA45";
var _9 = "#272B2B";
var _a = "#BDBDBD";
var _b = "#7D7D7D";
var _c = "#6A0DAD";
var _d = "#3A65FF";
var _e = "#4A90E2";
var _f = "#ffffff";
var _10 = "wCcHzMLD3NzDdZgamTQK";
var _11 = "BY9hagSGHLDrVCXpPm1Z";
var _12 = "biLyfYFePAF0is5w_f3a";
var _13 = "K9PNKkuuxDoxBomW9jRG";
var _14 = "1200px";
var _15 = "992px";
var _16 = "1366px";
var _17 = "768px";
var _18 = "1475px";
var _19 = "576px";
var _1a = "1600px";
var _1b = "320px";
var _1c = "2500px";
var _1d = "KEVdIrrNfQEadjLIMULv";
var _1e = "EmptinBUjP0Y5VA3CgJm";
export { _1 as "app", _2 as "base-link", _3 as "colorGradeEX", _4 as "colorGradeFR", _5 as "colorGradeGD", _6 as "colorGradeOU", _7 as "colorGradePR", _8 as "colorGradeVG", _9 as "colorNeutral", _a as "colorNeutralLighest", _b as "colorNeutralLight", _c as "colorNonOwnerSweetLine", _d as "colorOwnerSweetLine", _e as "colorRegularLinks", _f as "colorWhite", _10 as "e2eMode", _11 as "product-block-grid", _12 as "product-block-subgrid", _13 as "reset-button", _14 as "screenlg", _15 as "screenmd", _16 as "screenml", _17 as "screensm", _18 as "screenxlg", _19 as "screenxs", _1a as "screenxxlg", _1b as "screenxxs", _1c as "screenxxxlg", _1d as "sf-toolbar", _1e as "single-page-responsive-font-size" }
export default { "app": _1, "base-link": _2, "colorGradeEX": _3, "colorGradeFR": _4, "colorGradeGD": _5, "colorGradeOU": _6, "colorGradePR": _7, "colorGradeVG": _8, "colorNeutral": _9, "colorNeutralLighest": _a, "colorNeutralLight": _b, "colorNonOwnerSweetLine": _c, "colorOwnerSweetLine": _d, "colorRegularLinks": _e, "colorWhite": _f, "e2eMode": _10, "product-block-grid": _11, "product-block-subgrid": _12, "reset-button": _13, "screenlg": _14, "screenmd": _15, "screenml": _16, "screensm": _17, "screenxlg": _18, "screenxs": _19, "screenxxlg": _1a, "screenxxs": _1b, "screenxxxlg": _1c, "sf-toolbar": _1d, "single-page-responsive-font-size": _1e }
