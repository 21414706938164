// extracted by mini-css-extract-plugin
var _1 = "base-link";
var _2 = "#76F366";
var _3 = "#EB8632";
var _4 = "#ECC834";
var _5 = "#21FC87";
var _6 = "#EA4A30";
var _7 = "#C7EA45";
var _8 = "#272B2B";
var _9 = "#BDBDBD";
var _a = "#7D7D7D";
var _b = "#6A0DAD";
var _c = "#3A65FF";
var _d = "#4A90E2";
var _e = "#ffffff";
var _f = "personalAccessAdd";
var _10 = "personalAccessContent";
var _11 = "personalAccessFooter";
var _12 = "personalAccessForm";
var _13 = "personalAccessHeading";
var _14 = "personalAccessLeft";
var _15 = "personalAccessModal";
var _16 = "personalAccessRight";
var _17 = "personalAccessRightContent";
var _18 = "personalAccessRightEmpty";
var _19 = "personalAccessRightLoader";
var _1a = "product-block-grid";
var _1b = "product-block-subgrid";
var _1c = "recursiveCheckbox";
var _1d = "recursiveCheckboxLabel";
var _1e = "recursiveDescriptionIcon";
var _1f = "recursiveDescriptionInput";
var _20 = "recursiveDescriptionInputHidden";
var _21 = "recursiveDescriptionSecurityIcon";
var _22 = "recursiveLabel";
var _23 = "reset-button";
var _24 = "1200px";
var _25 = "992px";
var _26 = "1366px";
var _27 = "768px";
var _28 = "1475px";
var _29 = "576px";
var _2a = "1600px";
var _2b = "320px";
var _2c = "2500px";
var _2d = "single-page-responsive-font-size";
var _2e = "userOption";
var _2f = "userOptionCompany";
var _30 = "userOptionDescription";
var _31 = "userOptionName";
var _32 = "userSelectedOption";
var _33 = "userSelectedOptionName";
export { _1 as "base-link", _2 as "colorGradeEX", _3 as "colorGradeFR", _4 as "colorGradeGD", _5 as "colorGradeOU", _6 as "colorGradePR", _7 as "colorGradeVG", _8 as "colorNeutral", _9 as "colorNeutralLighest", _a as "colorNeutralLight", _b as "colorNonOwnerSweetLine", _c as "colorOwnerSweetLine", _d as "colorRegularLinks", _e as "colorWhite", _f as "personalAccessAdd", _10 as "personalAccessContent", _11 as "personalAccessFooter", _12 as "personalAccessForm", _13 as "personalAccessHeading", _14 as "personalAccessLeft", _15 as "personalAccessModal", _16 as "personalAccessRight", _17 as "personalAccessRightContent", _18 as "personalAccessRightEmpty", _19 as "personalAccessRightLoader", _1a as "product-block-grid", _1b as "product-block-subgrid", _1c as "recursiveCheckbox", _1d as "recursiveCheckboxLabel", _1e as "recursiveDescriptionIcon", _1f as "recursiveDescriptionInput", _20 as "recursiveDescriptionInputHidden", _21 as "recursiveDescriptionSecurityIcon", _22 as "recursiveLabel", _23 as "reset-button", _24 as "screenlg", _25 as "screenmd", _26 as "screenml", _27 as "screensm", _28 as "screenxlg", _29 as "screenxs", _2a as "screenxxlg", _2b as "screenxxs", _2c as "screenxxxlg", _2d as "single-page-responsive-font-size", _2e as "userOption", _2f as "userOptionCompany", _30 as "userOptionDescription", _31 as "userOptionName", _32 as "userSelectedOption", _33 as "userSelectedOptionName" }
export default { "base-link": _1, "colorGradeEX": _2, "colorGradeFR": _3, "colorGradeGD": _4, "colorGradeOU": _5, "colorGradePR": _6, "colorGradeVG": _7, "colorNeutral": _8, "colorNeutralLighest": _9, "colorNeutralLight": _a, "colorNonOwnerSweetLine": _b, "colorOwnerSweetLine": _c, "colorRegularLinks": _d, "colorWhite": _e, "personalAccessAdd": _f, "personalAccessContent": _10, "personalAccessFooter": _11, "personalAccessForm": _12, "personalAccessHeading": _13, "personalAccessLeft": _14, "personalAccessModal": _15, "personalAccessRight": _16, "personalAccessRightContent": _17, "personalAccessRightEmpty": _18, "personalAccessRightLoader": _19, "product-block-grid": _1a, "product-block-subgrid": _1b, "recursiveCheckbox": _1c, "recursiveCheckboxLabel": _1d, "recursiveDescriptionIcon": _1e, "recursiveDescriptionInput": _1f, "recursiveDescriptionInputHidden": _20, "recursiveDescriptionSecurityIcon": _21, "recursiveLabel": _22, "reset-button": _23, "screenlg": _24, "screenmd": _25, "screenml": _26, "screensm": _27, "screenxlg": _28, "screenxs": _29, "screenxxlg": _2a, "screenxxs": _2b, "screenxxxlg": _2c, "single-page-responsive-font-size": _2d, "userOption": _2e, "userOptionCompany": _2f, "userOptionDescription": _30, "userOptionName": _31, "userSelectedOption": _32, "userSelectedOptionName": _33 }
