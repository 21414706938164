var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { action, computed, observable } from 'mobx';
import settingsPreset from '@/assets/data/settingsPreset.json';
import B2BAPI from '@/b2b/api/B2BAPI';
import { sortRepository } from '@/catalog/repository/SortRepository';
import { viewTemplateRepository } from '@/collection/repository/ViewTemplateRepository';
import ConstantsAPI from '@/common/api/ConstantsAPI';
import useStore from '@/common/hooks/useStore';
import config from '@/config';
import { mediaSubFormatRepository } from '@/media/repository/MediaSubFormatRepository';
import { setupPresetGroupRepository } from '@/media/repository/SetupPresetGroupRepository';
import { setupPresetRepository } from '@/media/repository/SetupPresetRepository';
import { dictionaryRepository } from '../repositories/DictionaryRepository';
export default class ConfigurationStore {
    b2bFilters;
    scaleBaseUri;
    wsServerURL;
    b2bAPI;
    constantsAPI;
    constructor() {
        this.b2bAPI = new B2BAPI();
        this.constantsAPI = new ConstantsAPI();
    }
    get isConfigurationLoaded() {
        return Boolean(dictionaryRepository.dictionaries) && Boolean(setupPresetRepository.setupPresets);
    }
    fetchB2BFilters = () => {
        if (this.b2bFilters) {
            return Promise.resolve(this.b2bFilters);
        }
        return this.b2bAPI
            .fetchB2BFilters()
            .then((res) => res.data)
            .then(this.processAndApplyB2BList);
    };
    fetchServerConstants = () => this.constantsAPI
        .fetchAll()
        .then((res) => res.content)
        .then(this.processAndApplyConstants);
    processAndApplyConstants = (serverResponse) => {
        if (!serverResponse) {
            return;
        }
        config.PLAYER_JS_URL = serverResponse.web.player.js;
        config.PLAYER_CSS_URL = serverResponse.web.player.css;
        setupPresetGroupRepository.initSorts(serverResponse.productTypes);
        sortRepository.initSorts(serverResponse.dict.sorts, serverResponse.productTypes); // todo make serverResponse immutable
        dictionaryRepository.initDictionaries(serverResponse.dict);
        mediaSubFormatRepository.initMediaSubFormats(serverResponse.dict.subFormats);
        setupPresetRepository.initSetupPresets(serverResponse.dict.setupPresets);
        setupPresetRepository.initSetupPresetOrder(serverResponse.spOrder);
        setupPresetGroupRepository.initSetupPresetGroups(serverResponse.dict.setupPresetGroups);
        viewTemplateRepository.initViewTemplates(settingsPreset);
        this.scaleBaseUri = serverResponse.web.scaleBaseUri;
        this.wsServerURL = serverResponse.web.wsServerURL;
    };
    processAndApplyB2BList = (serverResponse) => {
        if (!serverResponse) {
            return;
        }
        const b2bByProductTypes = serverResponse.productTypes;
        const b2bAccounts = serverResponse.b2b;
        // filter every object in "productTypes" by ids from "b2b" array
        Object.keys(b2bByProductTypes).forEach((productType) => {
            b2bByProductTypes[productType] = b2bByProductTypes[productType]
                .map((b2bId) => b2bAccounts.find(({ id }) => id === b2bId))
                .filter((type) => !!type);
        });
        this.b2bFilters = b2bByProductTypes;
    };
}
__decorate([
    observable.ref
], ConfigurationStore.prototype, "b2bFilters", void 0);
__decorate([
    observable
], ConfigurationStore.prototype, "scaleBaseUri", void 0);
__decorate([
    observable
], ConfigurationStore.prototype, "wsServerURL", void 0);
__decorate([
    computed
], ConfigurationStore.prototype, "isConfigurationLoaded", null);
__decorate([
    action
], ConfigurationStore.prototype, "fetchB2BFilters", void 0);
__decorate([
    action
], ConfigurationStore.prototype, "fetchServerConstants", void 0);
__decorate([
    action
], ConfigurationStore.prototype, "processAndApplyConstants", void 0);
__decorate([
    action
], ConfigurationStore.prototype, "processAndApplyB2BList", void 0);
export const useConfigurationStore = () => useStore(ConfigurationStore);
export const configurationStore = new ConfigurationStore();
