import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import { useMount } from '@cutwise/utils';
import DragDropProvider from '@/common/components/DragDropProvider/DragDropProvider';
import ErrorBoundary from '@/common/components/ErrorBoundary/ErrorBoundary';
import ErrorChecker from '@/common/components/ErrorChecker/ErrorChecker';
import Header from '@/common/components/Header/Header';
import { useAppStore } from '@/common/stores/AppStore';
import { useConfigurationStore } from '@/common/stores/ConfigurationStore';
import { useContactsStore } from '@/contacts/stores/ContactsStore';
import { E2E_TESTS_ENABLED } from '@/test/constants/e2e';
import Auth from '@/user/services/auth/Auth';
import { useUserStore } from '@/user/stores/UserStore';
import styles from './App.module.less';
function App(props) {
    const { children } = props;
    const { isConfigurationLoaded } = useConfigurationStore();
    const { user, fetch, fetchCompanyUsers, isAuthorized } = useUserStore();
    const { enableCloudSettingsStorage } = useAppStore();
    const { initContactsStore } = useContactsStore();
    useMount(() => {
        Auth.showNoticeAboutSessionExpirationIfNeeded();
        Promise.all([fetch()]).then(() => {
            if (isAuthorized) {
                enableCloudSettingsStorage();
                initContactsStore();
                fetchCompanyUsers();
            }
        });
        if (E2E_TESTS_ENABLED) {
            document.body.classList.add(styles.e2eMode);
        }
    });
    if (!isConfigurationLoaded || !user) {
        return null;
    }
    return (_jsx(DragDropProvider, { children: _jsxs("div", { className: styles.app, children: [_jsx(Header, {}), _jsx(ErrorBoundary, { children: _jsx(ErrorChecker, { children: children }) })] }) }));
}
export default observer(App);
