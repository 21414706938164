// extracted by mini-css-extract-plugin
var _1 = "YzCIW8i4koHl7cD55h8q";
var _2 = "Q3801htlaXLFMEFJugOI";
var _3 = "#76F366";
var _4 = "#EB8632";
var _5 = "#ECC834";
var _6 = "#21FC87";
var _7 = "#EA4A30";
var _8 = "#C7EA45";
var _9 = "#272B2B";
var _a = "#BDBDBD";
var _b = "#7D7D7D";
var _c = "#6A0DAD";
var _d = "#3A65FF";
var _e = "#4A90E2";
var _f = "#ffffff";
var _10 = "GBdS3oFjA_JXmAobc1Cp";
var _11 = "vaj8GMEs7JdTBEdRWjLi";
var _12 = "hMf9RIbXonqcMjja3BcH";
var _13 = "XJ_GNvHLaNz6ZLPHKq4f";
var _14 = "v2ERFUQugiDSuX3jtnH1";
var _15 = "Jdc0p1QameeiCs_ofBEs";
var _16 = "Qjbw_ocHQ0CtDCDXukSj";
var _17 = "wlqQCNVNd4hHRJkE0KfQ";
var _18 = "ZvehZ9ZazAIZHPBbz0KR";
var _19 = "GQbylf9Q76cG0V9_inKv";
var _1a = "f0vCoQywbi8BgACXHMbO";
var _1b = "1200px";
var _1c = "992px";
var _1d = "1366px";
var _1e = "768px";
var _1f = "1475px";
var _20 = "576px";
var _21 = "1600px";
var _22 = "320px";
var _23 = "2500px";
var _24 = "_v8J3ETtmtSbh7AZiB_2";
export { _1 as "base-link", _2 as "bounce", _3 as "colorGradeEX", _4 as "colorGradeFR", _5 as "colorGradeGD", _6 as "colorGradeOU", _7 as "colorGradePR", _8 as "colorGradeVG", _9 as "colorNeutral", _a as "colorNeutralLighest", _b as "colorNeutralLight", _c as "colorNonOwnerSweetLine", _d as "colorOwnerSweetLine", _e as "colorRegularLinks", _f as "colorWhite", _10 as "loader", _11 as "loaderBlack", _12 as "loaderColored", _13 as "loaderLarge", _14 as "loaderMedium", _15 as "loaderSmall", _16 as "loaderWhite", _17 as "loaderXSmall", _18 as "product-block-grid", _19 as "product-block-subgrid", _1a as "reset-button", _1b as "screenlg", _1c as "screenmd", _1d as "screenml", _1e as "screensm", _1f as "screenxlg", _20 as "screenxs", _21 as "screenxxlg", _22 as "screenxxs", _23 as "screenxxxlg", _24 as "single-page-responsive-font-size" }
export default { "base-link": _1, "bounce": _2, "colorGradeEX": _3, "colorGradeFR": _4, "colorGradeGD": _5, "colorGradeOU": _6, "colorGradePR": _7, "colorGradeVG": _8, "colorNeutral": _9, "colorNeutralLighest": _a, "colorNeutralLight": _b, "colorNonOwnerSweetLine": _c, "colorOwnerSweetLine": _d, "colorRegularLinks": _e, "colorWhite": _f, "loader": _10, "loaderBlack": _11, "loaderColored": _12, "loaderLarge": _13, "loaderMedium": _14, "loaderSmall": _15, "loaderWhite": _16, "loaderXSmall": _17, "product-block-grid": _18, "product-block-subgrid": _19, "reset-button": _1a, "screenlg": _1b, "screenmd": _1c, "screenml": _1d, "screensm": _1e, "screenxlg": _1f, "screenxs": _20, "screenxxlg": _21, "screenxxs": _22, "screenxxxlg": _23, "single-page-responsive-font-size": _24 }
