// extracted by mini-css-extract-plugin
var _1 = "base-link";
var _2 = "#76F366";
var _3 = "#EB8632";
var _4 = "#ECC834";
var _5 = "#21FC87";
var _6 = "#EA4A30";
var _7 = "#C7EA45";
var _8 = "#272B2B";
var _9 = "#BDBDBD";
var _a = "#7D7D7D";
var _b = "#6A0DAD";
var _c = "#3A65FF";
var _d = "#4A90E2";
var _e = "#ffffff";
var _f = "link";
var _10 = "nav";
var _11 = "product-block-grid";
var _12 = "product-block-subgrid";
var _13 = "reset-button";
var _14 = "1200px";
var _15 = "992px";
var _16 = "1366px";
var _17 = "768px";
var _18 = "1475px";
var _19 = "576px";
var _1a = "1600px";
var _1b = "320px";
var _1c = "2500px";
var _1d = "single-page-responsive-font-size";
export { _1 as "base-link", _2 as "colorGradeEX", _3 as "colorGradeFR", _4 as "colorGradeGD", _5 as "colorGradeOU", _6 as "colorGradePR", _7 as "colorGradeVG", _8 as "colorNeutral", _9 as "colorNeutralLighest", _a as "colorNeutralLight", _b as "colorNonOwnerSweetLine", _c as "colorOwnerSweetLine", _d as "colorRegularLinks", _e as "colorWhite", _f as "link", _10 as "nav", _11 as "product-block-grid", _12 as "product-block-subgrid", _13 as "reset-button", _14 as "screenlg", _15 as "screenmd", _16 as "screenml", _17 as "screensm", _18 as "screenxlg", _19 as "screenxs", _1a as "screenxxlg", _1b as "screenxxs", _1c as "screenxxxlg", _1d as "single-page-responsive-font-size" }
export default { "base-link": _1, "colorGradeEX": _2, "colorGradeFR": _3, "colorGradeGD": _4, "colorGradeOU": _5, "colorGradePR": _6, "colorGradeVG": _7, "colorNeutral": _8, "colorNeutralLighest": _9, "colorNeutralLight": _a, "colorNonOwnerSweetLine": _b, "colorOwnerSweetLine": _c, "colorRegularLinks": _d, "colorWhite": _e, "link": _f, "nav": _10, "product-block-grid": _11, "product-block-subgrid": _12, "reset-button": _13, "screenlg": _14, "screenmd": _15, "screenml": _16, "screensm": _17, "screenxlg": _18, "screenxs": _19, "screenxxlg": _1a, "screenxxs": _1b, "screenxxxlg": _1c, "single-page-responsive-font-size": _1d }
